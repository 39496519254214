.hero {

	.section-inner {
		padding-top: $hero--padding-t__mobile;
		padding-bottom: $hero--padding-b__mobile;
	}
}

.hero-inner {

	// Affects only hero full
	> .hero-content + .hero-figure,
	> .hero-figure + .hero-content {
		margin-top: $hero--inner-padding-v_mobile;
	}

	> .hero-figure {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}
}

@include media( '<=medium' ) {

	.hero {

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $hero--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $hero--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.hero {

		.section-inner {
			padding-top: $hero--padding-t__desktop;
			padding-bottom: $hero--padding-b__desktop;
		}
	}

	.hero-inner {

		// Affects only hero full
		> .hero-content + .hero-figure,
		> .hero-figure + .hero-content {
			margin-top: $hero--inner-padding-v_desktop;
		}
	}
}


.banner {
	background: url(./../../../images/banner.jpg) no-repeat left 82px;
	background-size: cover;
}
.hero-content, .hero-content h1 {
	color: #ffffff!important;
}


.hero-inner {
	margin-top: -($split-item--padding-v__mobile / 2);

	&:last-of-type {
		margin-bottom: -($split-item--padding-v__mobile / 2);
	}

    &:not(:last-of-type) {
        margin-bottom: $split-item--padding-v__mobile / 2;
    }

	.hero-banner {
		padding-top: $split-item--padding-v__mobile / 2;
		padding-bottom: $split-item--padding-v__mobile / 2;
		display: flex;
		flex-direction: column;

		.hero-image {
			position: relative;
			width: 100%;

			img,
			svg,
			video {
				width: auto;
				height: auto;
				max-width: 100%;
				overflow: visible;
			}

			&.info-item-image-fill {

				img,
				svg,
				video {
					width: auto;
				}
			}
		}
	}
}

@include media( '<=medium' ) {

	.hero-inner {

		.hero-banner {

			.hero-content {
				margin-bottom: $split-item--inner-padding-v_mobile;
			}
		}

		&.invert-mobile {

			.hero-banner {

				.hero-content {
					order: 1;
					margin-bottom: 0 !important;
				}

				.hero-image {
					margin-bottom: $split-item--inner-padding-v_mobile;
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.hero-inner {
		margin-top: -($split-item--padding-v__desktop / 2);

		&:last-of-type {
			margin-bottom: -($split-item--padding-v__desktop / 2);
		}

	    &:not(:last-of-type) {
	        margin-bottom: $split-item--padding-v__desktop / 2;
	    }

		.hero-banner {
			padding-top: $split-item--padding-v__desktop / 2;
			padding-bottom: $split-item--padding-v__desktop / 2;
			flex-direction: row;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: flex-start;

			.hero-content {
				flex-shrink: 0;
				width: ($container--width-sm / 2) - $split-item-content--padding-h;
				margin-bottom: 0;
				margin-right: $split-item--inner-padding-h;
			}

			.hero-image {
				min-width: ($container--width-sm / 2) - $split-item-image--padding-h;
			}
		}

		&:not(.invert-desktop) .hero-banner:nth-child(even),
		&.invert-desktop .hero-banner:nth-child(odd) {
			justify-content: flex-end;

			.hero-content {
				order: 1;
				margin-left: $split-item--inner-padding-h;
				margin-right: 0;
			}
		}

		&:not(.invert-desktop) .hero-banner:nth-child(odd),
		&.invert-desktop .hero-banner:nth-child(even) {

			.hero-image {

				img,
				svg,
				video {
					margin-left: auto;
				}
			}
		}

		&.align-top {

			.v-item {
				align-items: flex-start;
			}
		}
	}
}

@include media( '>large' ) {

	.container-sm {

		.hero-inner {

			.hero-banner {

				.hero-content {
					width: ($container--width / 2) - $split-item-content--padding-h;
				}

				.hero-image {
					min-width: ($container--width / 2) - $split-item-image--padding-h;
				}
			}
		}
	}
}
